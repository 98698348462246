.archived-cmt-reports-container {
    margin: 0 70px;
    display: block;
}

.archived-cmt-reports-container h1 {
    font-size: 40px;
    float: left;
    margin: 30px 0;
}

.archived-cmt-reports-table table{
    font-size: 24px;
    clear: both;
    width: 100%;
    background-color: #F5F5F5;
    cursor: pointer;
} 

.archived-cmt-reports-table table, .archived-cmt-reports-table th, .archived-cmt-reports-table td {
    border-collapse: collapse;
    border: 1px solid #612A6C;
    text-align: left;
    padding: 7px 15px;
}   

.archived-cmt-reports-table .cmt {
    width: 25%;
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.archived-cmt-reports-table .reason {
    width: 25%;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.archived-cmt-reports-table .status {
    width: 10%;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}