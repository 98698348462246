.mod-user-management-container {
    margin: 0 70px;
    margin-bottom: 120px;
    display: block; 
}

.mod-user-management-container h2 {
    font-size: 40px;
    float: left;
    margin: 30px 0;
    font-weight: normal;
}

.mod-user-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.user-management-tb {
    font-size: 24px;
    clear: both;
    width: 100%;
    background-color: #F5F5F5;
    cursor: pointer;
    border-collapse: collapse;
    margin-bottom: 50px;
}

.user-management-tb th,
.user-management-tb td {
    border-collapse: collapse;
    border: 1px solid #612A6C;
    text-align: left;
    padding: 7px 15px;
}

.user-management-username {
    width: 30%;
}

.user-management-email {
    width: 30%;
}

.user-management-age{
    width: 10%;
}

.user-management-occupation {
    width: 30%;
}

.user-management-tb th {
    max-width: 20px;
    overflow-wrap: break-word;
}

.export-user-email {
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #F5F5F5;
    border: 1px solid #314397;
    font-size: 24px;
    color: #314397;
    font-weight: bold;
    right: 70px;
    width: 280px;
}

.export-user-email:hover , .new-mod-button:hover{
    background-color: #314397;
    color: #F5F5F5;
}

.new-mod-button{
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #F5F5F5;
    border: 1px solid #314397;
    font-size: 24px;
    color: #314397;
    font-weight: bold;
    right:0;
    right: 70px;
    width: 280px;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
