/* Parent container taking full viewport height */
.login-page {
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.login-main {
    display: flex;
    width: 80%; 
    align-items: center;
}

.login-section {
    width: 50%;
    padding: 20px;
}

.login-title {
    font-size: 40px;
    color: #612a6c;
    font-weight: bold;
}

.input-label {
    font-size: 24px;
    color: #612a6c;
    display: block;
    margin-top: 20px;
}

.input-field-username {
    width: 385px;
    height: 55px;
    background-color: #f5f5f5;
    border-radius: 10px;
    color: #612a6c;
    border: 1px solid #612a6c;
    font-size: 24px;
    padding: 0 10px;
    box-sizing: border-box;
}

.user-login-password-input-container {
    position: relative;
    width: 385px; /* Adjust width as needed */
}

.user-login-password-input-container .input-field {
    padding-right: 40px; /* Add padding to make space for the icon */
}

.user-login-eye-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #612a6c;
}

/* forgot your ... ? */
.login-page p {
    color: #612a6c;
    font-size: 20px; /* do not change to 24px */
    margin-top: 10px;
    margin-left: 180px;
    cursor: pointer;
}

/* password */
.forgot-password {
    color: #2275F1; 
    text-decoration: none; 
}

/* remove underline  */
.forgot-password a {
    text-decoration: none; 
}

.login-button {
    width: 385px;
    height: 42px;
    background-color: #F5F5F5;
    border: 1px solid #314397;
    color: #314397;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    box-sizing: border-box;
}

.login-btn-spacing {
    padding-top: 10px
}

/* google login and sign up button */
.gsi-material-button {
    /* -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none; */
    background-color: WHITE;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #1f1f1f;
    cursor: pointer;
    font-family: 'Roboto', arial, sans-serif;
    font-size: 24px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
    transition: background-color .218s, border-color .218s, box-shadow .218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    max-width: 400px;
    min-width: min-content;
  }
  
  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }
  
  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    font-family: 'Roboto', arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
    color: #1f1f1f;
  }
  
  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity .218s;
    transition: opacity .218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  .gsi-material-button:disabled {
    cursor: default;
    background-color: #ffffff61;
    border-color: #1f1f1f1f;
  }
  
  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }
  
  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }
  
  .gsi-material-button:not(:disabled):active .gsi-material-button-state, 
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: #303030;
    opacity: 12%;
  }
  
  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  }
  
  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: #303030;
    opacity: 8%;
  }
  

/* change colour if hover over button */
.login-button:hover {
    background-color: #314397;
    border: 1px solid #314397;
    color: #F5F5F5;
}

.signup-section {
    background-color: rgba(185, 234, 237, 0.45);
    width: 547px;
    height: 433px;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #612a6c;
}

.signup-title {
    font-size: 40px;
    color: #612a6c;
    font-weight: bold;
    line-height: 1.3;
    padding-left: 40px;
}

.signup-text {
    display: block;
    font-size: 24px;
    color: #612a6c;
    opacity: 0.7;
    margin-left: 40px;
}

.signup-button {
    width: 274px;
    height: 63px;
    background-color: #f5f5f5;
    border: 1px solid #314397;
    color: #314397;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 20px;
    margin-left: 220px;
}

.signup-button:hover {
    background-color: #314397;
    border: 1px solid #314397;
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
  background-color: #F5F5F5 !important; 
}

.swal2-title-custom {
  color: #272727 !important; 
  font-size: 36px !important;
}

.swal2-confirmbutton-custom {
  background-color: #612a6c !important; 
  color: #F5F5F5 !important;
}