/* NewForumPost.css */

.new-forum-post-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: none;
    border-radius: 10px;
    font-size: 24px;
}

.new-forum-post-container h1 {
    font-size: 40px;
    margin-bottom: 20px;
    color: #612A6C;
    text-align: left;
}

.new-forum-post-container form {
    display: grid;
    gap: 10px;
}

.new-forum-post-container .form-group {
    display: grid;
    gap: 5px;
    color: #612A6C;
}

.new-forum-post-container .title-category-group {
    display: flex;
    gap: 31px; 
    align-items: flex-start;
}

.new-forum-post-container label {
    font-weight: bold;
}

.new-forum-post-container input[type="text"],
.new-forum-post-container select,
.new-forum-post-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #612A6C;
    background-color: rgba(185, 234, 237, 0.4);
    border-radius: 10px;
    color: #612A6C;
    font-size: 24px;
}

.new-forum-post-container textarea {
    resize: vertical; 
}

.new-forum-post-container input[type="text"].title-input {
    width: 810px; 
}

.new-forum-post-container input[type="text"].title-input:focus {
    outline: none; 
    border-color: #612A6C; 
}

.new-forum-post-container select.category-select {
    width: 359px; 
    text-align: left;
    background-color: #F5F5F5;
}

.new-forum-post-container select.category-select:focus {
    outline: none; 
    border-color: #612A6C; 
}

.new-forum-post-container textarea.description-textarea {
    width: 1200px; 
    height: 260px;
    font-family: 'Poppins', sans-serif; 
}

.new-forum-post-container textarea.description-textarea:focus {
    outline: none; 
    border-color: #612A6C; 
}

.new-forum-post-container .anonymous-option {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #612A6C;
    font-size: 24px;
}

.new-forum-post-container .anonymous-button {
    width: 70px; 
    height: 36px;
    padding: 5px 10px;
    background-color: #F5F5F5;
    border: 1px solid #314397;
    border-radius: 10px;
    cursor: pointer;
    font-size: 24px;
    color: #314397;
    text-align: center;
    font-weight: bold;
}

.new-forum-post-container .anonymous-button:hover {
    background-color: #314397; 
    color: #F5F5F5;
}

.new-forum-post-container .anonymous-button.selected {
    background-color: #314397; 
    color: #F5F5F5;
}

.new-forum-post-container button[type="submit"] {
    padding: 10px 20px; 
    background-color: #F5F5F5;
    color: #314397;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #314397;
    width: 240px; 
    height: 47px;
    margin-left: 982px;
    font-size: 24px;
    text-align: center;
}

.new-forum-post-container button[type="submit"]:hover {
    background-color: #314397; 
    color: #F5F5F5;
}

/* Pop-out message styling */
.new-forum-pop-out-message {
    position: fixed;
    width: 700px;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px; 
    background-color: #E5E4F4;
    color: #612A6C;
    font-size: 24px;
    z-index: 100;
    text-align: center;
    border: 2px solid #272727;
    flex-direction: column;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}

.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}

