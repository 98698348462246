.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh; 
    text-align: center;
    background-color: #e5e4f4; 
    color: #272727;
}

.notfound-icon{
    height: 200px;
    width: 200px;
    color: #612a6c;
}
  
.not-found h1 {
    font-size: 40px;
    margin: 0;
}
  
.not-found p {
    font-size: 24px;
    margin-top: 10px;
}

