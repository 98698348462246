/* ArchivedUserPosts.css */
.archived-post-container {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
    background-color: #E5E4F4;
}

.archived-post-container h1 {
    font-size: 40px;
    color: #612A6C;
    text-align: left;
}

.archived-post-container p {
    font-size: 24px;
    background-color: transparent;
    color: #612A6C;
    margin: 5px 0;
    padding: 0;
}

.archived-post-desc {
    clear: both;
    font-size: 24px;
    margin-top: 10px;
    line-height: 1.3;
}

.archived-post-container p strong {
    color: #612A6C;
}

.archived-post-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    height: 45px;
}

.archived-post-status{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    width: 250px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    font-size: 24px;
    color:#612A6C;
    margin: 10px 0; 
}

.archived-post-comment-input-header{
    font-size: 24px;
    margin-bottom: 10px;
}

.archived-post-comments-container {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-top: 20px;
}

.archived-post-comments-list {
    width: 1156px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    font-size: 24px;
    line-height: 1.6;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(185, 234, 237, 0.4);
}

.archived-post-comment-header{
    padding-top: 10px;
}

.archived-post-comments-list span {
    font-size: 24px;
}

.archived-post-comment {
    border-bottom: 1px solid #612A6C; 
    padding-bottom: 5px;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}