.reportedposts-container {
    margin: 0 70px;
    display: block;
}

.reportedposts-container h1 {
    font-size: 40px;
    float: left;
    margin: 30px 0;
}

.reportedposts-table table{
    font-size: 24px;
    clear: both;
    width: 100%;
    background-color: #F5F5F5;
    cursor: pointer;
} 

.reportedposts-table table, .reportedposts-table th, .reportedposts-table td {
    border-collapse: collapse;
    border: 1px solid #612A6C;
    text-align: left;
    padding: 7px 15px;
}   

.reportedposts-table .reason {
    width: 25%;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reportedposts-table .datetime {
    width: 25%;
}

.reportedposts-table .poststatus {
    width: 15%;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
  
  