.modlanding-outline {
    margin: 140px 0px;
}
.modlanding-container {
    display: flex;
    justify-content: space-around;
    margin: 40px 10px;
    cursor: pointer;
}

.modlanding-container p {
    font-size: 32px;
    text-align: center;
    line-height: 1.3;
}

.blue {
    background-color: #B9EAED;
}

.pink {
    background-color: #F7EBEC;
}

.box {
    height: 250px;
    width: 240px;
    font-size: 70px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}