/* header */
.terms-header {
    text-align: left;
    color: #612A6C;
    padding: 50px 0 0 100px;
    font-size: 40px;
}

/* content */
.terms-p {
    font-size: 24px;
    line-height: 1.3;
    padding: 10px 100px;
    text-align: justify;
    color: #612A6C; 
}

.terms-p strong {
    font-size: 30px;
    padding-top: 5px;
}

/* links within terms-p */
.terms-p a {
    text-decoration: none;
    color: #612A6C; 
}

.terms-p a:hover {
    color: #F5F5F5;
    background-color: #314397;
    text-decoration: none;
}
