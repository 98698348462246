/* header */
.about-header {
    text-align: left;
    color: #314397;
    padding: 50px 0 0 100px;
    font-size: 40px;
}

/* image and logo */
.about-image-container{
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.aboutus-img {
    width: 300px;
    height: 300px;
}

.aboutus2-img {
    width: 300px;
    height: 300px;
}

.ws-logo {
    width: 300px;
    height: 300px;
}

/* content */
.about-p {
    font-size: 24px;
    line-height: 1.3;
    padding: 10px 100px;
    color: #314397;
    text-align: justify;
}

