.review-new-post-container {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
}

.review-new-post-container h1 {
    font-size: 40px;
    float: left;
}

.review-new-post-content {
    clear: both;
    font-size: 24px;
    margin-bottom: 80px;
} 

.review-new-post-category {
    font-weight: bold;
    margin-bottom: 0;
}

.review-new-post-content p {
    margin-top: 5px;
    line-height: 1.3;
}

.review-post-approve, .review-post-reject {
    background-color: #F5F5F5;
    border: 1px solid #314397;
    color: #314397;
    border-radius: 10px;
    float: right;
    font-size: 24px;
    width: 160px;
    margin: 20px 0;
    padding: 5px 0;
    font-weight: bold;
}

.review-post-approve {
    margin-right: 15px;
}

.review-post-approve:hover, .review-post-reject:hover {
    background-color: #314397;
    color: #F5F5F5;
    cursor: pointer;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
  
.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}
  
.swal2-cancelbutton-custom{
    background-color: red!important; 
    color: #F5F5F5 !important;
}