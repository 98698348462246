/* import poppins font family */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

#graph {
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 50px; 
    font-family: 'Poppins', sans-serif;
}

.graph-header {
    font-size: 40px;
    font-weight: 700;
    margin-left: 40px;
    align-self: flex-start;
}
.graph-row {
    display: flex;
    justify-content: center;
    gap: 100px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
}

.graph-item {
    position: relative;
    width: 100%; 
    max-width: 500px; 
    transition: transform 0.2s ease, width 0.2s ease; /* hover scaling effect */
}

.graph-item:hover {
    transform: scale(1.1);
}

.graph-picture {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.graph-text {
    position: absolute;
    top: 20px;
    left: 30px;
    right: 30px;
    color: #612A6C;
    padding: 5px;
    font-size: 40px;
    border-radius: 5px;
    font-weight: 700;
    line-height: 1.3;
}

.graph-btn-right,
.graph-btn-left {
    position: absolute;
    bottom: 50px; 
    padding: 10px 20px;
    font-size: 24px;
    color: #314397;
    font-weight: 700;
    background-color: #F5F5F5;
    border: 1px solid #314397;
    border-radius: 8px;
    cursor: pointer;

}

.graph-btn-right {
    right: 30px;
}

.graph-btn-left {
    left: 30px; 
}

.graph-btn-right:hover,
.graph-btn-left:hover {
    background-color: #314397; 
    color: #F5F5F5;
}