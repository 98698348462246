.reportcmt-container {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
    background-color: #E5E4F4;
}

.reportcmt-container h1 {
    font-size: 40px;
    color: #612A6C;
    text-align: left;
}
 
.reportcmt-container p {
    font-size: 24px;
    color: #612A6C;
    line-height: 1.3;
}

.reportcmt-cmt {
    display: flex;
    margin-top: 50px;
}

.reportcmt-box {
    width: 20px;
    height: 120px;
    background-color: #F5F5F5;
    border: 1px solid #612A6C;
    border-radius: 10px;
    align-self: center;
}

.reportcmt-user {
    margin-left: 20px;
    align-self: center;
    line-height: 1.3;
}

.reportcmt-user p {
    margin: 5px 0;
}

.reportcmt-reason {
    font-size: 24px;
    display: block;
    margin-top: 50px
}

.reportcmt-reason textarea {
    margin-top: 10px;
    box-sizing: border-box; 
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #612A6C;
    background-color: #F5F5F5;
    padding: 10px;
    font-family: 'Poppins', sans-serif; 
    color: #612A6C;
    font-size: 24px;
}

.reportcmt-container button {
    background-color: #F5F5F5;
    border-radius: 10px;
    border: 1px solid #314397;
    cursor: pointer;
    float: right;
    width: 230px;
    height: 45px;
    font-size: 24px;
    color: #314397;
    margin-top: 50px;
    margin-bottom: 50px;
}

.reportcmt-container button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}

.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}