.what-is-mfs {
    margin: 40px 90px;
    animation-duration: 3s;
    animation-name: fadein; /* css animation */
}

.what-is-mfs-h1 {
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    position: relative; 
    display: inline-block; 
    padding-bottom: 10px; 
}

/* header underline */
.what-is-mfs-h1::after { 
    content: ''; /* pseudo element */
    display: block;
    width: 100%; 
    height: 2px; 
    background-color: #612A6C;
    position: absolute; 
    bottom: 0;
    left: 0;
}

.what-is-mfs-p {
    text-align: justify;
    line-height: 1.3;
    font-size: 24px;
    margin-top: 0;
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
  
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* slideshow styles */
.what-is-mfs-slideshow-container {
    max-width: 2000px;
    position: relative;
    margin: auto;
    padding: 10px;
}

/* Hide the slides by default */
.what-is-mfs-mySlides {
    display: none;
    margin: 50px;
}

.what-is-mfs-slide-image {
    vertical-align: middle;
    width: 100%; 
}

.what-is-mfs-mySlides h2 {
    text-align: center;
    font-size: 40px;
}

.what-is-mfs-mySlides-div {
    display: flex;
    justify-content: space-between;
    height: 400px;
}

.what-is-mfs-mySlides-div-text-pink {
    width: 45%;
    background-color: #F7EBEC;
}

.what-is-mfs-mySlides-div-text-pink, .what-is-mfs-mySlides-div-text-blue {
    font-size: 24px;
    line-height: 1.3;
    text-align: justify;
    padding: 0 30px;
}

.what-is-mfs-mySlides-div-text-blue {
    width: 45%;
    background-color: #B9EAED;
}

.what-is-mfs-mySlides-div-text-header {
    text-align: center;
    font-size: 32px;
    padding-top: 20px;
}

.what-is-mfs-mySlides-div-img {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.what-is-mfs-mySlides-div-img img {
    height: 300px;
}

.what-is-msf-historical-context-header {
    background-color: #F5F5F5;
    border: 1px solid #272727;
    border-radius: 8px;
    width: 400px;
    display: block;
    margin: 0 auto;
    margin-bottom: 70px;
}

.what-is-msf-historical-context {
    margin-top: 80px;
    background-color: #F7EBEC;
    border: 1px solid #612A6C;
    border-radius: 8px;
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.what-is-msf-historical-context p {
    font-size: 24px;
    line-height: 1.3;
    text-align: justify;
    padding: 50px 30px;
    margin: 0 auto;
    width: 70%;
}

.what-is-msf-challenges-header {
    width: 400px;
    height: 180px;
    margin: 0 auto;
    display: block;
    margin-bottom: 70px;
    position: relative; 
    text-align: center;
    padding: 50px;
}

.what-is-msf-challenges-blob {
    z-index: 1; 
    position: absolute; 
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    height: 300px;
    border: 1px solid #612A6C;
    background-color: #612A6C;
    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;

}

.what-is-msf-challenges-header h2 {
    color: #F5F5F5;
    font-size: 40px;
    z-index: 2; 
    text-align: center; 
    position: relative; 
}

.what-is-msf-challenges {
    background-color: #F7EBEC;
    border-radius: 8px;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: -190px;
}

.what-is-msf-challenges p {
    font-size: 24px;
    line-height: 1.3;
    text-align: justify;
    padding: 190px 100px;
    margin: 0 auto;
}

.what-is-mfs-negative-impacts-header h2 {
    color: #314397;
    font-size: 40px;
}

.what-is-mfs-negative-impacts-p, 
.what-is-mfs-negative-impacts-section-1, 
.what-is-mfs-negative-impacts-section-2 {
 font-size: 24px;
 text-align: justify;
 line-height: 1.3;
}

.what-is-mfs-negative-impacts-view-1, .what-is-mfs-negative-impacts-view-2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.what-is-mfs-negative-impacts-icon-1, .what-is-mfs-negative-impacts-icon-2 {
    display: flex;
    align-items: center;
}
.what-is-mfs-negative-impacts-icon-1 img, .what-is-mfs-negative-impacts-icon-2 img {
    height: 200px;
    width: 200px;
}

.what-is-mfs-negative-impacts-section-1 {
    color: #314397;
    background-color: #B9EAED;
    margin-left: 50px;
    padding: 10px 25px;
    height: 300px;
}

.what-is-mfs-negative-impacts-section-2 {
    color: #612A6C;
    background-color: #F7EBEC;
    margin-right: 50px;
    padding: 5px 25px;
    height: 300px;
}

/* next and previous buttons */
.what-is-mfs-prev, .what-is-mfs-next {
    cursor: pointer;
    position: fixed;
    top: 50%; 
    transform: translateY(-50%); 
    width: auto;
    padding: 16px;
    color: #272727;
    background-color: #F5F5F5;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease; 
    border-radius: 0 3px 3px 0;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center; 
    border: none;
}

.what-is-mfs-prev {
    left: 25px;
    border-radius: 3px 0 0 3px;
}
.what-is-mfs-next {
    right: 25px;
    border-radius: 0 3px 3px 0;
}

.what-is-mfs-prev:hover, .what-is-mfs-next:hover {
    background-color: #612A6C;
    color: #F5F5F5;
}

/* dots (pagination indicators) */
.what-is-mfs-dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.what-is-mfs-dot-container{
    text-align: center;
    width: 100%;
}

.what-is-mfs-active, .what-is-mfs-dot:hover {
    background-color: #717171;
}

/* fading animation for the slides */
.what-is-mfs-fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from { opacity: 0.4 }
    to { opacity: 1 }
}