.mod-contact-us-page {
    font-family: 'Poppins', sans-serif;
    width: 100%;
    background-color: #e5e4f4;
}

.mod-contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
    background-color: rgba(247, 235, 236, 0.5);
    width: 900px;
    border: 1px solid #272727;
    border-radius: 10px;
    margin-left: 300px;
    margin-right: 300px;
    margin-bottom: 0px;
    margin-top: 100px;
    box-sizing: border-box;
}

.mod-contact-header {
    width: 833px;
    height: 221px;
    background-color: rgba(247, 235, 236, 0.5);
    border: 1px solid #272727;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 20px;
}

.mod-contact-title {
    font-size: 40px;
    color: #612a6c;
    margin-bottom: 20px;
    font-weight: normal;
}

.mod-contact-subtitle {
    font-size: 20px;
    color: #612a6c;
}

.mod-contact-form-content {
    width: 833px;
    height: 600px;
    background-color: rgba(247, 235, 236, 0.5);
    border: 1px solid #272727;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.mod-contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 30px;
}

.mod-form-group-contact {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}

.mod-form-group-contact label {
    font-size: 24px;
    font-weight: 400;
    color: #314397;
    margin-bottom: 5px;
    display: block;
}

.input-field-mod-contact-us {
    width: 762px;
    height: 63px;
    background-color: #f5f5f5;
    border: 1px solid #314397;
    border-radius: 10px;
    font-size: 24px;
    padding: 10px;
    box-sizing: border-box;
    color: #314397;
}

.input-field-help-decs {
    width: 762px;
    height: 153px;
    background-color: #f5f5f5;
    border: 1px solid #314397;
    border-radius: 10px;
    font-size: 24px;
    padding: 10px;
    box-sizing: border-box;
    color: #314397;
    font-family: 'Poppins', sans-serif;
}

.close-form-button {
    width: 385px;
    height: 42px;
    background-color: #f7f9fb;
    border: 1px solid #314397;
    color: #314397;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
}

.close-form-button:hover{
    background-color: #314397;
    color: #f5f5f5;
}

.contact-us-page select.input-field {
    text-align-last: center;
    font-size: 24px;
    color: #314397;
    background-color: #f5f5f5;
}

.archived-contact-form {
    font-size: 24px;
    color: #314397;
    line-height: 1.3;
    margin-left: 300px;
    margin-right: 300px;
    margin-bottom: 50px;
    margin-top: 0;
    padding-left: 10px;
    width: 833px;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
  
.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}