.reportpost-container {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
    background-color: #E5E4F4;
}

.reportpost-container h1 {
    font-size: 40px;
    color: #612A6C;
    text-align: left;
}

.desc {
    clear: both;
    font-size: 24px;
    margin-bottom: 80px;
}

.authCategory {
    font-weight: bold;
    margin-bottom: 0;
}

.desc p {
    margin-top: 5px;
    line-height: 1.3;
}

.reportpost-reason {
    font-size: 24px;
    display: block;
    margin-top: 50px
}

.reportpost-reason textarea {
    margin-top: 10px;
    box-sizing: border-box; 
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #612A6C;
    background-color: rgba(185, 234, 237, 0.4);
    padding: 10px;
    font-family: 'Poppins', sans-serif; 
    color: #612A6C;
    font-size: 24px;
}

.reportpost-container button {
    background-color: #F5F5F5;
    border-radius: 10px;
    border: 1px solid #314397;
    cursor: pointer;
    float: right;
    width: 230px;
    height: 45px;
    font-size: 24px;
    color: #314397;
    margin-top: 50px;
    font-weight: bold;
}

.reportpost-container button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}

.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}