.rules-container {
    max-width: 1120px;
    height: 478px;
    margin: 0 auto;
    padding: 20px;
    background-color: #E5E4F4;
    border: 2px solid #272727;
    border-radius: 10px;
    align-items: center;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    margin-top: 100px;
    box-sizing: border-box; 
}

.rules-container h1 {
    width: auto;
    font-size: 32px;
    color: #612A6C;
    margin-bottom: 20px;
    padding-left: 20px;
    border-bottom: 2px solid #612A6C;
    text-align: left;
}

.rules-container ul {
    list-style-type: none;
    padding-left: 0; 
    margin: 0;
}

.rules-container li {
    font-size: 32px;
    color: #612A6C;
    margin-bottom: 10px;
}
