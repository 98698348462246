div {
    color: #612A6C;
}

/* adjust line height */
.landing, .outline-landing {
    line-height: 1.2;
}

/* landing page content / first thing the user sees when they open the web application */
.landing {
    height: 90vh;
    display: flex;
    justify-content: space-around;
    align-items: center; 
    margin: 0 100px;
}

.content {
    padding-right: 50px;
    font-size: 38px;
}

.landing button {
    background-color: #F5F5F5;
    border: 1px solid #314397;
    width: 200px;
    height: 40px;
    border-radius: 8px;
    color: #314397;
    font-size: 24px;
    font-weight: bold;
}

.landing button:hover {
    color: #F5F5F5;
    background-color: #314397;
}

/* about us and terms of use and policies  */
.outline-landing {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
}

.title {
    width: 30%;
    padding-left: 50px;
    align-self: center;
}

.title h1 {
    font-size: 40px;    
    text-align: left;
    font-weight: bold;
}

.title p {
    font-size: 24px;
}

.title a {
    text-decoration: none;
    color: #612A6C;
}

.title a:hover{
    background-color: #314397;
    color:#F5F5F5;
}

.description {
    width: 65%;
    margin-right: 20px;
    padding: 10px 40px;
    font-size: 24px;
    /* Remove the fixed height */
    /* height: 40vh; */
    /* Allow the height to adapt to content */
    padding: 10px 40px;
    min-height: auto; /* Ensure it adjusts based on content */
    text-align: justify;
}

.outline-landing:nth-child(2) .description {
    background-color: #F7EBEC;
}

.outline-landing:nth-child(3) .description {
    background-color: rgba(185, 234, 237, 0.55);
}

/* footer */
.footer {
    display: block;
    position: relative;
    background-color: #F7EBEC;
    height: 15vh;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
    align-items: center;
}

.footer-left {
    margin-left: 80px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

}

.footer-left a {
    text-decoration: none;
    font-weight: bold;
    color: #612A6C;
    font-size: 40px;
}

.footer-right {
    width: 40%;
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    margin-right: 250px;
}

.footer-right h2 {
    margin-bottom: 5px;
}

.footer-right ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
}

.footer-right a {
    text-decoration: none;
    color: #612A6C;
    font-size: 24px;
}


