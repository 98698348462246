.useracc-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.useracc-container h1 {
    font-size: 40px;
    font-weight: bold;
    color: #612a6c;
    text-align: center;
    margin: 50px 0;
}

.useracc-container form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 180px; 
    grid-row-gap: 20px;
    justify-content: center; 
}

.useracc-form {
    display: flex;
    flex-direction: column;
}

.useracc-form label {
    font-size: 20px;
    font-weight: bold;
    color: #612a6c;
    margin-bottom: 5px;
    align-self: flex-start;
}

.useracc-form input, .useracc-form select {
    width: 388px;
    height: 50px;
    border: 1px solid #612a6c;
    border-radius: 10px;
    font-size: 20px;
    color: #612a6c;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #F5F5F5;
}

.useracc-form select {
    text-align: left;
}

.update-button, .user-account-reset-button {
    width: 388px;
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    color: #314397;
    background-color: #F5F5F5;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #314397;
    box-sizing: border-box;
    margin-top: 100px;
}

.update-button:hover, .user-account-reset-button:hover, .delete-user-button:hover {
    border: 1px solid #314397;
    color: #F5F5F5;
    background-color: #314397;
}

.delete-user-button {
    width: 388px;
    height: 50px;
    font-size: 24px;
    font-weight: bold;
    color: #314397;
    background-color: #F5F5F5;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #314397;
    box-sizing: border-box;
    margin-left: 530px;
    margin-top: 10px;
}

/*Delete Dropdown*/
.delete-dropdown-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.delete-dropdown-bar-wrapper {
    display: flex;
    justify-content: center; 
    width: 800px; 
}

.delete-dropdown-bar {
    line-height: 1.3;
    background-color: #F5F5F5;
    color: #612a6c;
    text-align: center;
    transition: height 0.3s ease; 
    height: 40px; 
    overflow: hidden; 
    width: 940px; 
    position: fixed;
    bottom: 20px;
    padding: 10px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #612a6c;
}
  
.delete-dropdown-bar.expanded {
    height: auto; 
}
  
.delete-dropdown-bar-content {
    transition: opacity 0.3s ease;
}
  
.delete-dropdown-bar-button svg {
    margin-right: 5px;
}

.delete-dropdown-bar-button{
    background: none;
    border: none;
    color: #612a6c;
    cursor: pointer;
    padding: 10px;
    font-size: 24px;
    display: flex;
    align-items: center;
}

.delete-dropdown-content{
    font-size: 24px;
    text-align: left;
    padding: 10px;
}