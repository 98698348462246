/* ForumOverview.css */

/*information blurb*/
.forum-info-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    opacity: 0.75;
    width: 1246px;
}

.forum-info-bar-title {
    font-weight: bold;
    font-size: 24px;
}

.forum-info-bar-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.forum-info-bar-icon.collapsed {
    transform: rotate(0deg);
}

.forum-info-bar-icon.expanded {
    transform: rotate(0deg);
}

.forum-info-blurb {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 20px;
    background-color: #F5F5F5;
    color: #612A6C;
    font-size: 24px;
    margin: 0;
    width: 100%;
    text-align: justify;
}

/* Container for the entire forum */
.forum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto; 
    max-width: 1226px; 
}

/* Title section */
.forum-title {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    margin-bottom: 0px;
}

/* Forum title styling */
.forum-title h1 {
    font-size: 40px;
    color: #612A6C;
    margin-right: 20px; 
}

/* Search bar styling */
.forum-search-bar {
    height: 26px; 
    width: 701px;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #314397;
    border-radius: 10px; 
    text-align: left; 
    color: #314397;
    background-color: #F5F5F5;
    margin: 0 20px; 
}

.forum-search-bar:focus {
    outline: none; 
    border-color: #314397; 
    text-align: left;
}

.forum-search-bar::placeholder {
    color: #314397; 
    font-size: 24px;
    text-align: center; 
}

/* Container for the filter button and dropdown */
.forum-filter-container {
    position: relative; 
}

/* Dropdown list container styling */
.forum-filter-dropdown {
    position: absolute;
    top: 100%; 
    left: -184px;
    width: 300px;
    height: auto;
    background-color: #F5F5F5; 
    border: 1px solid #314397;
    border-radius: 10px;
    z-index: 10; 
    text-align: center;  
    margin-top: 25px;
}

/* List inside the dropdown */
.forum-filter-dropdown ul {
    list-style-type: none; 
    padding: 0;
    margin: 0 auto;
    margin-top: 10px; 
}

.forum-filter-dropdown li {
    padding: 0;
    margin: 0 auto; 
}

.forum-filter-dropdown-link {
    display: block; 
    text-decoration: none; 
    color: #314397;
    font-size: 24px;
    margin-bottom: 10px; 
    border-bottom: 1px solid #314397; 
    line-height: 1.3;
    height: 35px;
}

.forum-filter-dropdown-link:hover {
    background-color: none; 
}

.forum-filter-container:hover .forum-filter-dropdown {
    display: block;
}

.forum-filter-dropdown-link-loan {
    display: block; 
    text-decoration: none; 
    color: #314397;
    font-size: 24px;
    margin-bottom: 10px; 
    border-bottom: 1px solid #314397; 
    line-height: 1.3;
    height: 35px;;
}

.forum-filter-dropdown-link-loan:last-child {
    border-bottom: none;
    margin-bottom: 5px;
}

/* Filter button styling */
.forum-filter-button {
    height: 47px; 
    width: 117px;
    background-color: #F5F5F5;
    color: #314397;
    border: 1px solid #314397;
    border-radius: 10px;
    cursor: pointer; 
    font-size: 24px;
    text-align: center;
    font-weight: bold;
}

/* Hover state for the filter button */
.forum-filter-button:hover {
    background-color: #314397; 
    color: #F5F5F5;
    border: 1px solid #314397;
}

/* Styling for select elements inside the dropdown */
.forum-filter-dropdown select {
    height: 47px;
    width: 300px;
    padding: 5px; 
    border: none;
    font-size: 24px;
    color: #314397;
    border-radius: 10px;
    text-align: center; 
}

/* Focus state for select elements */
.forum-filter-dropdown select:focus {
    outline: none; 
    border-color: #314397; 
}

/* Styling for individual forum post box */
.forum-box {
    display: flex; 
    justify-content: space-between; 
    width: 1226px;
    height: 190px;
    border: 1px solid #314397;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background-color: rgba(185, 234, 237, 0.4);
    margin-top: 20px;
}

/* Forum post title styling */
.forum-box h2 {
    font-size: 24px;
    color: #612A6C;
    margin-bottom: 5px;
}

.forum-author-category{
    margin-top: 5px;
    margin-bottom: 20px;
    background-color:transparent;
}

.forum-description {
    margin-top: 10px;
    line-height: 1.3;
}

.forum-box p {
    font-size: 24px;
    color: #612A6C;
    width: 1006px; 
}

.forum-box p strong {
    color: #612A6C;
}

/* Remove default underline for links */
.forum-link {
    text-decoration: none; 
}

/* Container for comment icon and text */
.forum-image-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
}

.forum-icon {
    width: 50px; 
    height: 50px;
    align-items: center;
    margin-top:50px;
    color: #272727;
}

/* Styling for comment text */
.forum-image-container span{
    font-size: 20px; 
    width: 160px;
    text-align: center;
    color: #272727;
    margin-top: 10px;
}

.forum-new-post-button-container {
    display: none; 
}

.forum-new-post-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 50px;
    width: 210px;
    background-color: #F5F5F5;
    color: #314397;
    border: none;
    border-radius: 10px;
    font-size: 24px;
    cursor: pointer;
    border: 1px solid #314397;
    z-index: 100; 
    text-align: center;
    font-weight: bold;
}

.forum-new-post-button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
  }
  
  .swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
  }