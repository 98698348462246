.indiv-reportedpost {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
}

.indiv-reportedpost-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.indiv-reportedpost h1 {
    font-size: 40px;
}

.indiv-reportedpost-status{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    width: 250px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    font-size: 24px;
    color:#612A6C;
    margin: 10px 0; 
}

.reportedpost-content {
    clear: both;
    font-size: 24px;
    margin-bottom: 80px;
} 

.authCategory {
    font-weight: bold;
    margin-bottom: 0;
}

.reportedpost-content p {
    margin-top: 5px;
    line-height: 1.3;
}

.post-reason label {
    display: block;
    font-size: 24px;
}

.post-reason textarea {
    font-family: 'Poppins', sans-serif; 
    background-color: #F5F5F5;
    font-size: 24px;
    color: #612A6C;
    vertical-align: text-top;
    width: 100%;
    height: 155px;
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    resize: none;
    border-radius: 8px;
    border: 1px solid #612A6C;
    line-height: 1.3;
}

.post-approve, .post-reject {
    background-color: #F5F5F5;
    border: 1px solid #314397;
    color: #314397;
    border-radius: 10px;
    float: right;
    font-size: 24px;
    width: 160px;
    margin: 20px 0;
    padding: 5px 0;
    font-weight: bold;
}

.post-approve {
    margin-right: 15px;
}

.post-approve:hover, .post-reject:hover {
    background-color: #314397;
    color: #F5F5F5;
    cursor: pointer;
}

.archived-info {
    font-size: 24px;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
  
.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}
  
.swal2-cancelbutton-custom{
    background-color: red!important; 
    color: #F5F5F5 !important;
}