.my-post-detail-container {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
    background-color: #E5E4F4;
}

.my-post-detail-container h1 {
    font-size: 40px;
    color: #612A6C;
    text-align: left;
    margin: 0px;
}

.my-post-detail-container p {
    font-size: 24px;
    background-color: transparent;
    color: #612A6C;
    margin: 5px 0;
    padding: 0;
}

.desc {
    clear: both;
    font-size: 24px;
    margin-top: 10px;
    line-height: 1.3;
}

.my-post-detail-container p strong {
    color: #612A6C;
}

.title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    height: auto;
}

.my-post-detail-status{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    width: 250px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    font-size: 24px;
    color:#612A6C;
    margin: 10px 0; 
}

.my-post-detail-buttons{
    margin-top: 15px;
    text-align: right;
}

.my-post-detail-edit-button {
    height: 42px;
    width: 125px;
    background-color: #F5F5F5;
    color:#314397;
    border: 1px solid #314397;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    margin-right: 25px;
    font-weight: bold;
}
  
.my-post-detail-delete-button {
    height: 42px;
    width: 125px;
    background-color: #F5F5F5;
    color:#314397;
    border: 1px solid #314397;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.comment-input-container {
    display: flex;
    align-items: center;
}

.comment-input {
    width: 1133px;
    height: 35px;
    padding: 10px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    color: #612A6C;
    background-color: #F5F5F5;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
}

.comment-input::placeholder {
    color: #612A6C;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
}

.comment-input-header{
    font-size: 24px;
    margin-bottom: 10px;
}

.submit-comment-button {
    position: absolute;
    height: 55px;
    width: 70px;
    background-color: transparent;
    color: #314397;
    border: none;
    padding:0;
    cursor: pointer;
    font-size: 24px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1070px;
}

.submit-comment-button:hover {
    background-color: none;
}

.my-post-detail-edit-button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

.my-post-detail-delete-button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

.comments-container {
    display: flex;
    align-items: flex-start;
    gap: 15px;
    margin-top: 5px;
}

.comments-list {
    width: 1123px;
    background-color: rgba(185, 234, 237, 0.4);
    border: 1px solid #612A6C;
    border-radius: 10px;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 24px;
    line-height: 1.6;
}

.comment-header{
    padding-top: 10px;
}

.comments-list span {
    font-size: 24px;
}

.comment {
    border-bottom: 1px solid #612A6C; 
    padding-bottom: 5px;
}

.report-comment {
    position: absolute;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-left: 1000px;
}

.report-icon {
    font-size: 36px; 
}

.report-comment:hover {
    background-color: transparent;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}

.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}

.swal2-cancelbutton-custom{
    background-color: red!important; 
    color: #F5F5F5 !important;
}


