.my-post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; 
    margin: 20px auto; 
    max-width: 1226px; 
}

.my-post-title {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    margin-bottom: 20px;
}

.my-post-title h1 {
    font-size: 40px;
    color: #612A6C;
    margin-right: 20px; 
}

.my-post-search-bar {
    height: 26px; 
    width: 701px;
    padding: 10px;
    font-size: 24px;
    border: 1px solid #314397;
    border-radius: 10px; 
    text-align: left; 
    color: #314397;
    background-color: #F5F5F5;
    margin: 0 20px; 
}

.my-post-search-bar:focus {
    outline: none; 
    border-color: #314397; 
    text-align: left;
}

.my-post-search-bar::placeholder {
    color: #314397; 
    font-size: 24px;
    text-align: center;
}

.my-post-filter-container {
    position: relative; 
}

.my-post-filter-dropdown {
    position: absolute;
    top: 100%; 
    left: 0;
    width: 300px;
    left: -183px;
    height: auto;
    background-color: #F5F5F5; 
    border: 1px solid #314397;
    border-radius: 10px;
    z-index: 100; 
    display: none;
    margin-top: 25px;
    box-sizing: border-box; 
}

.my-post-filter-container.show .my-post-filter-dropdown {
    display: block; 
}

.my-post-filter-dropdown ul {
    list-style-type: none; 
    padding: 0;
    margin: 0 auto;
}

.my-post-filter-dropdown li {
    padding: 0;
    margin: 0 auto; 
}

.my-post-filter-dropdown-item {
    text-align: center;
}

.my-post-filter-dropdown-item button {
    display: block;
    text-decoration: none;
    color: #314397;
    font-size: 24px;
    height:auto;
    margin: 0 auto;
    background: none;
    cursor: pointer;
    width: 100%; 
    border:none;
    padding: 10px;
    border-bottom: 1px solid #314397;
}

.my-post-filter-dropdown-item strong {
    background-color: none;
}

.my-post-filter-dropdown-item button:hover {
    background-color: transparent;
}

.my-post-filter-dropdown-item strong {
    font-size: 24px;
    color: #314397;
    display: block; 
    margin-bottom: 0px; 
    border-bottom: 1px solid #314397;
    padding: 10px;
}

.my-post-filter-button {
    height: 47px; 
    width: 117px;
    background-color: #F5F5F5;
    color: #314397;
    border: 1px solid #314397;
    border-radius: 10px;
    cursor: pointer;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
}

.my-post-filter-button:hover {
    background-color: #314397; 
    color: #F5F5F5; 
}

.my-post-filter-dropdown-link-category {
    display: block; 
    text-decoration: none; 
    color: #314397;
    font-size: 24px;
    border-bottom: 1px solid #314397; 
    line-height: 1.3;
    height: 35px;
}

.my-post-filter-dropdown-link-category:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}

.my-post-box {
    display: flex; 
    justify-content: space-between;
    width: 1226px;
    height: 190px;
    border: 1px solid #314397;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background-color: rgba(185, 234, 237, 0.4)
}

.my-post-box h2 {
    font-size: 24px;
    color: #612A6C;
    margin-bottom: 5px;
}

.my-post-content-header {
    display: flex;
    justify-content: space-between; 
    width: 720px; 
    padding:5px;
    text-align: center;
    margin-top: 10px;
}

.my-post-content-header h2 {
    font-size: 24px;
    color: #612A6C;
    margin:0;
}

.my-post-status-container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    margin-top: 10px;
}

.my-post-status {
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 210px;
    height: 47px;
    font-size: 24px;
    color: #612A6C;
    text-align: center;
    border: 1px solid #314397;
    border-radius: 10px;
    background-color: #BEF2B5;
}

.my-post-status strong {
    color: #314397;
}

.my-post-author-category {
    margin-top: 5px;
    margin-bottom: 20px;
    background-color: transparent;
}

.my-post-description {
    margin-top: 10px;
    line-height: 1.3;
}

.my-post-box p {
    font-size: 24px;
    color: #612A6C;
    width: 1006px; 
}

.my-post-box p strong {
    color: #612A6C;
}

.my-post-link {
    text-decoration: none; 
}

.my-post-new-post-button-container {
    display: none; 
}

.my-post-new-post-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 50px;
    width: 210px;
    background-color: #F5F5F5;
    color: #314397;
    border: none;
    border-radius: 10px;
    font-size: 24px;
    cursor: pointer;
    border: 1px solid #314397;
    z-index: 100; 
    text-align: center;
    font-weight: bold;
}

.my-post-new-post-button:hover {
    background-color: #314397; 
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
