.contact-us-page {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background-color: #e5e4f4;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

.contact-header {
  width: 833px;
  height: 221px;
  background-color: rgba(247, 235, 236, 0.5);
  border: 1px solid #272727;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 20px;
}

.contact-title {
  font-size: 40px;
  color: #612a6c;
  margin-bottom: 20px;
  font-weight: normal;
}

.contact-subtitle {
  font-size: 20px;
  color: #612a6c;
}

.contact-form-container {
  width: 833px;
  height: 600px;
  background-color: rgba(247, 235, 236, 0.5);
  border: 1px solid #272727;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
}

.form-group-contact {
  width: 100%;
  margin-bottom: 20px;
}

.form-group-contact label {
  font-size: 24px;
  font-weight: 400; 
  color: #314397;
  margin-bottom: 5px;
  display: block;
}

.input-field-contact-us {
  width: 762px;
  height: 63px;
  background-color: #f5f5f5; 
  border: 1px solid #314397;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #314397; 
}

.input-field-help-decs{
  width: 762px;
  height: 153px;
  background-color: #f5f5f5; 
  border: 1px solid #314397;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #314397; 
  font-family: 'Poppins', sans-serif;
}

.submit-button {
  width: 385px;
  height: 42px;
  background-color: #f7f9fb;
  border: 1px solid #314397;
  color: #314397;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 40px; 
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 0px;
  height: 20px;
  display: flex;
  align-items: center;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 500px;
  height: 260px;
  font-size: 24px;
  color: #612a6c;
  border: 2px solid #272727;
  background-color: #e5e4f4;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.contact-us-page select.input-field {
  text-align-last: center; 
  font-size: 24px;
  color: #314397; 
  background-color: #f5f5f5; 
}

/*customise the alert message*/
.swal2-popup-custom {
  background-color: #F5F5F5 !important; 
}

.swal2-title-custom {
  color: #272727 !important; 
  font-size: 36px !important;
}

.swal2-confirmbutton-custom {
  background-color: #612a6c !important; 
  color: #F5F5F5 !important;
}
