/* NarrativeDetail.css */

.narrative-detail {
    max-width: 1155px;
    margin: 0 auto;
    color: #612A6C;
    padding: 20px;
    font-size: 24px;
}

.narrative-detail-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.narrative-detail-head h2 {
    color: #612A6C;
    font-size: 40px;
}

.narrative-detail-head div {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

.narr-edit-delete {
    background-color: #F5F5F5;
    font-size: 20px;
    border: 1px solid #314397;
    border-radius: 10px;
    color: #314397;
    width: 120px;
    height: 42px;
    width: 125px;
    cursor: pointer;
    font-weight: bold;
}

.narr-edit-delete:hover {
    background-color: #314397;
    color: #F5F5F5;
}

.narrative-detail p {
    line-height: 1.3;
    margin-bottom: 15px;
}

.narrative-detail .disclaimer {
    color: #FF6969;
    font-size: 24px;
    margin-top: 10px;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}

.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}

.swal2-cancelbutton-custom{
    background-color: red!important; 
    color: #F5F5F5 !important;
}