.indiv-reportedcmt {
    max-width: 1155px;
    margin: 0 auto;
    padding: 20px;
    background-color: #E5E4F4;
}

.indiv-reportedcmt h1 {
    font-size: 40px;
    color: #612A6C;
}

.indiv-reportedcmt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.indiv-reportedcmt h1 {
    font-size: 40px;
}

.indiv-reportedcmt-status{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    width: 250px;
    border: 1px solid #612A6C;
    border-radius: 10px;
    font-size: 24px;
    color:#612A6C;
    margin: 10px 0; 
}

.indiv-reportedcmt p {
    font-size: 24px;
    color: #612A6C;
    line-height: 1.3;
}

.desc {
    margin-bottom: 10px;
}

desc p {
    margin-top: 5px;
    line-height: 1.3;
}

.authCategory {
    margin-bottom: 0;
}

.reportcmt-content {
    display: flex;
    margin-top: 50px;
}

.report-box {
    width: 20px;
    height: 120px;
    background-color: #F5F5F5;
    border: 1px solid #612A6C;
    border-radius: 10px;
    align-self: center;
}

.report-user {
    margin-left: 20px;
    align-self: center;
    line-height: 1.3;
}

.report-user p {
    margin: 5px 0;
}

.cmt-reason {
    font-size: 24px;
    display: block;
    margin-top: 50px
}

.cmt-reason textarea {
    margin-top: 10px;
    box-sizing: border-box; 
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #612A6C;
    background-color: #F5F5F5;
    padding: 10px;
    font-family: 'Poppins', sans-serif; 
    color: #612A6C;
    font-size: 24px;
}

.reportcmt-container button {
    background-color: #F5F5F5;
    border-radius: 10px;
    border: 1px solid #314397;
    cursor: pointer;
    float: right;
    width: 230px;
    height: 45px;
    font-size: 24px;
    color: #314397;
    margin-top: 50px;

}

.reportcmt-container button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

.cmt-approve, .cmt-reject {
    background-color: #F5F5F5;
    border: 1px solid #314397;
    color: #314397;
    border-radius: 10px;
    float: right;
    font-size: 24px;
    width: 160px;
    margin: 20px 0;
    padding: 5px 0;
    font-weight: bold;
}

.cmt-approve {
    margin-right: 15px;
}

.cmt-approve:hover, .cmt-reject:hover {
    background-color: #314397;
    color: #F5F5F5;
    cursor: pointer;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
  
.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}
  
.swal2-cancelbutton-custom{
    background-color: red!important; 
    color: #F5F5F5 !important;
}