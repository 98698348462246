/* Main Container */
#additional-resource {
  width: 100%;
  max-width: 1200px; /* Fixed page width */
  margin: 0 auto; /* Center the container */
  padding: 20px 50px;
  background-color: #E5E4F4; /* Light lavender */
  font-family: 'Arial', sans-serif; /* Clean and simple font */
}

/* Page Header */
.page-header {
  font-size: 40px;
  color: #612A6C;
  text-align: left;
  margin-bottom: 40px;
  font-weight: normal;
}

/* Resources Section */
.resources-section {
  margin-bottom: 60px;
  text-align: center;
}

/* Resource Category Box */
.resource-category-box {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

.resource-title {
  font-size: 40px;
  color: #612A6C;
  margin: 0;
  font-weight: normal;
  text-decoration:underline
}

/* Links Container */
.links {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

/* Resource Box for "Other budgeting resources" */
.resource-box-budgeting {
  width: 240px;
  height: 150px;
  background-color: #B9EAED;
  border: 1px solid #612A6C;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #612A6C;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 10px;
  box-sizing: border-box;
}

/* Resource Box for "Domestic Violence Resources" */
.resource-box-feeling-unsafe {
  width: 240px;
  height: 150px;
  background-color: #B9EAED;
  border: 1px solid #612A6C;
  border-radius: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  color: #612A6C;
  padding: 10px;
  padding-top: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.resource-unsafe-content {
  flex-direction: row; 
  justify-content: space-between;
  align-items: flex-start; 
}

.resource-unsafe-content p {
  flex: 1; 
}

.links a {
  text-decoration: none;
  color: #612A6C; /* Adjust color as needed */
}

.links a:hover {
  text-decoration: none; /* Optional: Add underline on hover for better user experience */
}

.sub-resource-title{
  font-size: 24px;
}

.sub-resource-title-nh{
  padding-top: 15px;
  font-size: 24px;
}

.add-link{
  text-decoration: none;
}

.add-link:hover{
  background-color: #314397;
  color: #F5F5F5;
}
