/*information blurb*/
.cal-info-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin: 20px 80px 0;
    opacity: 0.75;
}

.cal-info-bar-title {
    font-weight: bold;
    font-size: 24px;
}

.cal-info-bar-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.cal-info-bar-icon.collapsed {
    transform: rotate(0deg);
}

.cal-info-bar-icon.expanded {
    transform: rotate(0deg);
}

.cal-info-blurb {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 20px;
    background-color: #F5F5F5;
    color: #612A6C;
    font-size: 24px;
    margin: 0 80px;
    text-align: justify;
}

.outline-calc {
    display: flex;
    width: 100%;
}

.container-calc {
    height: 700px;
    width: 40%;
    margin: auto;
    position: relative;
    font-size: 24px;
    padding-top: 40px;
}

h1 {
    font-weight: normal;
    text-align: center;
    font-size: 36px;
}

/* loan calculator and breakdown section outline */
.loan-calc, .loan-breakdown {
    bottom: 0;
    position: absolute;
    width: 100%;
    border: 1px solid #272727;   
    border-radius: 16px; 
}

/* loan calculator */
.calc-header {
    position: absolute; 
    z-index: 0;
    border: 1px solid #272727;
    border-radius: 16px;
    height: 720px;
    width: 50%;
    bottom: 0;
    text-align: center;
}

.calc-header h1 {
    font-size: 32px;
}

.loan-calc {
    margin-left: 35px;
    z-index: 1;
    background-color: #F7EBEC;
    height: 650px;
    width: 100%;
    display: flex;
    bottom: 0;
    justify-content: center; 
    align-items: center;
}

.loan-form {
    margin: 0 0 0 -60px;
    padding-top: 10px;
    max-width: 400px;
    height: auto; 
}

.loan-form label {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.loan-form input, .loan-form select {
    display: inline-block;
    color: #612A6C;
    border: 1px solid #612A6C;
    background-color: #F5F5F5;
    border-radius: 8px;
    height: 40px;
    font-size: 20px;
    padding: 0 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.right {
    width: 110px;
    margin-right: 20px;
    box-sizing: border-box;

}

.left {
    width: 265px;
    box-sizing: border-box;
}

.select-input, .type-input {
    width: 395px;
}

#calc-button {
    width: 395px;
    border: 1px solid #314397;
    color: #314397;
    background-color: #F5F5F5;
    font-weight: bold;
    box-sizing: border-box;
}

#calc-button:hover {
    background-color: #314397;
    color: #F5F5F5;
}

.calc-header h1 {
    font-size: 30px;
}

/* loan breakdown */
.loan-breakdown {
    background-color: rgba(185, 234, 237, 0.4);
    bottom: 0;
    height: 500px;
}

.loan-breakdown h1 {
    font-size: 32px;
    padding: 30px 0 20px 0;
}

.breakdown {
    padding: 5px 70px;
    align-items: center;
}

.breakdown-disclaimer {
    color: #FF6969;
}

/* individual breakdown */
.item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.item label {
    text-align: left;
}

.item span {
    text-align: right;
}

/* Need additional budgeting resources? Look here. */
.resources {
    text-align: left;
    font-size: 24px;
    color: #612A6C;
    margin-left: 73px; /* align with loan calculator outline */
}

.resources a {
    text-decoration: none;
    color: #612A6C;
}

.resources a:hover {
    color: #F5F5F5;
    background-color: #314397;
    text-decoration: none;
}

/*assumption for calculator*/
.cal-assump-info-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin: 20px 80px 0;
    opacity: 0.75;
}

.cal-assump-info-bar-title {
    font-weight: bold;
    font-size: 24px;
}

.cal-assump-info-bar-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.cal-assump-info-bar-icon.collapsed {
    transform: rotate(0deg);
}

.cal-assump-info-bar-icon.expanded {
    transform: rotate(0deg);
}

.cal-assump-info {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    background-color: #F5F5F5;
    color: #612A6C;
    font-size: 24px;
    padding-right: 30px;
    margin: 0 80px;
    text-align: justify;
}

.cal-assump-info-list{
    list-style-type: circle;
}

/* different type of loan blurb */
.diff-type-loan-info-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin: 20px 80px 0;
    opacity: 0.75;
}

.diff-type-loan-info-bar-title {
    font-weight: bold;
    font-size: 24px;
}

.diff-type-loan-info-bar-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.diff-type-loan-info-bar-icon.collapsed {
    transform: rotate(0deg);
}

.diff-type-loan-info-bar-icon.expanded {
    transform: rotate(0deg);
}

.diff-type-loan-info-blurb {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 20px;
    padding-right: 40px;
    background-color: #F5F5F5;
    color: #612A6C;
    font-size: 24px;
    margin: 0 80px;
    text-align: justify;
}

.diff-type-loan-info-blurb h2{
    margin: 0;
}

.diff-type-loan{
    list-style-type: disc;
    line-height: 1.2;
    margin: 0;
}

.loan_img_container{
    display: flex;
    justify-content: center; 
    align-items: center; 
}

.loan_img{
    height: 200px;
    width: 200px;
    margin: 10px;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}