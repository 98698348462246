/*information blurb*/
.nar-info-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    opacity: 0.75;
    width: 1246px;
}

.nar-info-bar-title {
    font-weight: bold;
    font-size: 24px;
}

.nar-info-bar-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.nar-info-bar-icon.collapsed {
    transform: rotate(0deg);
}

.nar-info-bar-icon.expanded {
    transform: rotate(0deg);
}

.nar-info-blurb {
    border: 1px solid #F5F5F5;
    border-radius: 5px;
    padding: 20px;
    background-color: #F5F5F5;
    color: #612A6C;
    font-size: 24px;
    margin: 0;
    width: 100%;
    text-align: justify;
}

.narrativesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto; 
    max-width: 1226px; 
}

/* Title section */
.narrativeTitle {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 100%;
    margin-bottom: 0px;
}

/* Narrative title styling */
.narrativeTitle h1 {
    font-size: 40px;
    color: #612A6C;
    margin-right: 20px; 
}

/* Search bar styling */
.searchBar {
    height: 23px; 
    width: 701px;
    padding: 10px; 
    font-size: 24px;
    border: 1px solid #314397;
    border-radius: 10px; 
    text-align: left; 
    color: #314397;
    background-color: #F5F5F5;
    margin: 0 20px; 
}

/* Search bar focus state styling */
.searchBar:focus {
    outline: none; 
    border-color: #314397; 
    text-align: left;
}

.searchBar::placeholder {
    color: #314397;
    font-size: 24px;
    text-align: center;
}

/* Container for the filter button and dropdown */
.filterContainer {
    position: relative; 
}

/* Dropdown list container styling */
.filterDropdown {
    position: absolute;
    top: 100%; 
    width: 300px;
    left: -184px;
    height: auto;
    border: 1px solid #314397;
    border-radius: 10px;
    z-index: 10;
    text-align: center; 
    background-color: #F5F5F5;
    margin-top: 25px; 
}

.filterDropdownList {
    list-style-type: none; 
    padding: 0;
    margin: 0 auto; 
    margin-top: 10px; 
}

.filterDropdownItem {
    padding: 0;
    margin: 0 auto; 
}

.filterDropdownLink {
    display: block; 
    text-decoration: none; 
    color: #314397;
    font-size: 24px;
    margin-bottom: 10px; 
    line-height: 1.3;
    border-bottom: 1px solid #314397; 
    height: 35px;
}

.filterDropdownLink:hover {
    background-color: none; 
}

.filterContainer:hover .filterDropdown {
    display: block; 
}

.filterDropdownLink-categories{
    display: block; 
    text-decoration: none; 
    color: #314397;
    font-size: 24px;
    margin-bottom: 10px; 
    line-height: 1.3;
    border-bottom: 1px solid #314397; 
    height: 35px;
}

.filterDropdownLink-categories:last-child {
    border-bottom: none;
    margin-bottom: 0px;
}

/* Filter button styling */
.filterButton {
    height: 47px;
    width: 117px;
    background-color: #F5F5F5;
    color: #314397;
    border: 1px solid #314397;
    border-radius: 10px; 
    cursor: pointer; 
    font-size: 24px;
    font-weight: bold;
}

.filterButton:hover {
    background-color: #314397; 
    color: #F5F5F5;
    border: 1px solid #314397;
}

.narrativeBox {
    width: 1226px;
    height: 230px;
    border: 1px solid #314397;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px; 
    background-color: rgba(185, 234, 237, 0.4);
    margin-top: 20px;
}

.narrativeBox h2 {
    font-size: 24px;
    color: #314397;
    margin-bottom: 5px; 
}

.narrativeBox p {
    font-size: 24px;
    color: #314397;
}

.narrativeCategory{
    margin-top: 5px;
    margin-bottom: 20px;
}

.narrativeDescription{
    margin-top:10px;
    line-height: 1.3;
}

.narrativeBox p strong {
    color: #314397;
}

.narrativeLink {
    text-decoration: none;
}

.mod-add-narrative {
    width: 210px;
    background-color: #F5F5F5;
    font-size: 24px;
    color: #314397;
    border: 1px solid #314397;
    border-radius: 10px;
    position: fixed;
    bottom: 20px; 
    right: 20px;
    padding: 10px 20px; 
    cursor: pointer; 
    z-index: 1000;
    font-weight: bold;
}

.mod-add-narrative:hover {
    background-color: #314397;
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}