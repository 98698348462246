.create-mod-account-page {
    font-family: 'Poppins', sans-serif;
    width: 100%;
  }
  
.create-mod-account-page .create-mod-account-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
  
.create-mod-account-page .create-mod-title {
    font-size: 40px;
    font-weight: bold;
    color: #612a6c;
    text-align: center;
    margin: 50px 0;
  }
  
  .create-mod-account-page form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 200px;
    grid-row-gap: 20px;
    justify-content: center;
  }
  
  .create-mod-account-page .create-mod-group {
    display: flex;
    flex-direction: column;
  }
  
  .create-mod-account-page label {
    font-size: 24px;
    font-weight: bold;
    color: #612a6c;
    margin-bottom: 5px;
    align-self: flex-start;
  }
  
  .create-mod-account-page .create-mod-password-input-container {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .create-mod-account-page input[type="text"], 
  .create-mod-account-page input[type="email"], 
  .create-mod-account-page input[type="password"], 
  .create-mod-account-page select {
    width: 388px;
    height: 50px;
    border: 1px solid #612a6c;
    border-radius: 10px;
    font-size: 24px;
    color: #612a6c;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #F5F5F5;
  }
  
  .create-mod-account-page select {
    text-align: left;
  }
  
  .create-mod-account-page .eye-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: #612a6c;
  }
  
  .create-mod-account-page .create-mod-group-terms {
    grid-column: 2 / 3;
    display: flex;
    padding-left: 15px;
  }
  
  .create-mod-account-page .create-mod-group-terms input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }
  
  .create-mod-account-page .create-mod-group-terms label {
    font-size: 20px;
    color: #612a6c;
    align-self: flex-start;
    font-weight: normal;
    padding-left: 5px;
    line-height: 1.3;
  }
  
  .create-mod-account-page .create-mod-group-terms span {
    color: #2275F1;
    text-decoration: none;
  }
  
  .create-mod-account-page .submit-button {
    width: 388px;
    height: 50px;
    grid-column: 2 / 3;
    font-size: 24px;
    font-weight: bold;
    color: #314397;
    background-color: #f7f9fb;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #314397;
    box-sizing: border-box;
  }
  
  .create-mod-account-page .submit-button:hover {
    border: 1px solid #314397;
    color: #f7f9fb;
    background-color: #314397;
  }

/*customise the alert message*/
.swal2-popup-custom {
  background-color: #F5F5F5 !important; 
}

.swal2-title-custom {
  color: #272727 !important; 
  font-size: 36px !important;
}

.swal2-confirmbutton-custom {
  background-color: #612a6c !important; 
  color: #F5F5F5 !important;
}

