/* enable scrollbar on all pages (stops the nav bar from shifting around) */
html {
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
}

.nav-right a {
    text-decoration: none;
    font-size: 24px;
    color: #612A6C;;
}

/* change nav to blue if on that page */
.nav-right a.active {
    color: #314397;
    font-weight: bold;
}

.nav-right {
    float: right;
    width: 65%;
    margin-right: 50px;
}

.nav-left {
    float: left;
    margin-left: 80px;
    margin-top: 15px;
    color: #612A6C;
}

.nav-right ul {
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
} 

/* WillowSphere page title */
.nav-left a {
    text-decoration: none;
    font-weight: bold;
    color: #612A6C;
    font-size: 40px;
}

/* account button dropdown */
.dropdown {
    position: relative;
    color: #314397;
    border: 1px solid #314397;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    z-index: 2;
    align-content: center;
}

.dropdown-list {
    padding: 7px 20px;
    width: 100px;
    text-align: center;
}

/* change account dropdown to hex #314397 */
.dropdown-list a {
    color: #314397;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #F5F5F5;
    text-align: center;
    border: 1px solid #314397;
    border-radius: 5px;
    top: 100%;
    width: 170px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%); /* center align dropdown content */
}

.dropdown-content a {
    display: block;
    padding: 5px 5px;
    margin-bottom: 0;
    border-bottom: 1px solid #314397;
    font-size: 24px;
}

.dropdown-content a:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-list:hover {
    background-color: #314397;
}

.dropdown-list:hover a {
    color: #F5F5F5;
}

/* horizontal border line  */
hr {
    width: 95%;
    border: none;
    border-top: 1px solid #612A6C;
}