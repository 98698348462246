.mod-new-narrative-page {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background-color: #e5e4f4;
}

.mod-new-narrative-content {
  margin: 0 auto; 
  max-width: 1200px; 
  align-items: center;
}

.mod-new-narrative-title {
  font-size: 40px;
  color: #612a6c;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 400; 
}

.mod-new-narrative-label {
  font-size: 24px;
  color: #612a6c;
  text-align: left;
  margin-bottom: 0px;
  font-weight: bold;
}

.input-field-narrative-title-input {
  width: 1200px;
  height: 55px;
  border: 1px solid #612a6c;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #612a6c; 
  font-family: 'Poppins', sans-serif; 
  background-color: #b9eaed;
}

.mod-new-narrative-form {
  display: flex;
  flex-direction: column;
}

.mod-new-narrative-form .form-group-new-narrative {
  margin-bottom: 20px;
}

.mod-new-narrative-form .form-group-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group-cat-occ {
  width: 100%;
  max-width: 757px;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.input-field-occupation-input {
  width: 360px;
  height: 55px;
  border: 1px solid #612a6c;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #612a6c; 
  font-family: 'Poppins', sans-serif; 
  background-color: #b9eaed;
}

.input-field-category-input {
  width: 360px;
  height: 55px;
  border: 1px solid #612a6c;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #612a6c; 
  font-family: 'Poppins', sans-serif; 
  background-color: #F5F5F5;
}

.form-group-narr-desc {
  color: #612a6c;
}

.input-field-narrative-description {
  width: 1178px;
  height: 246px;
  border-radius: 10px;
  background-color: #b9eaed;
  align-items: center;
  font-size: 24px;
  color: #612a6c;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #612a6c;
}

.upload-button {
  width: 241px;
  height: 47px;
  background-color: #f7f9fb;
  border: 1px solid #314397;
  color: #314397;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  align-self:flex-end;
  margin-top: 20px; 
  margin-bottom: 20px;
}

.confirmation-popup,
.success-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e5e4f4;
  border: 1px solid #272727;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  width: 500px;
  height: 250px;
}

.confirmation-popup .confirmation-text,
.success-popup .success-text {
  font-size: 24px;
  color: #612a6c;
  margin-bottom: 50px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-around;
}

.confirmation-button {
  width: 235px;
  height: 44px;
  background-color: #f7f9fb;
  border: 1px solid #314397;
  color: #314397;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.yes-button {
  margin-right: 20px;
}

.no-button {
  margin-left: 20px;
}

/*customise the alert message*/
.swal2-popup-custom {
  background-color: #F5F5F5 !important; 
}

.swal2-title-custom {
  color: #272727 !important; 
  font-size: 36px !important;
}

.swal2-confirmbutton-custom {
  background-color: #612a6c !important; 
  color: #F5F5F5 !important;
}

.swal2-cancelbutton-custom{
  background-color: red!important; 
  color: #F5F5F5 !important;
}