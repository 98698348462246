.neoliberalism {
    margin: 40px 90px;
    animation-duration: 3s;
    animation-name: fadein; /* css animation */
}

.neoliberalism-h1 {
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    position: relative; 
    display: inline-block; 
    padding-bottom: 10px; 
}

/* header underline */
.neoliberalism-h1::after { 
    content: ''; /* pseudo element */
    display: block;
    width: 100%; 
    height: 2px; 
    background-color: #612A6C;
    position: absolute; 
    bottom: 0;
    left: 0;
}

.neoliberalism-p {
    text-align: justify;
    line-height: 1.3;
    font-size: 24px;
    margin-top: 0;
}

.neolib-significant-changes-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 40px 0;
}

.neolib-significant-changes-header {
    font-weight: bold;
    font-size: 24px;
    padding-left: 10px;
}

.neolib-significant-changes-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
} 

.neolib-significant-changes {
    display: flex;
    align-items: center;
    margin: 40px 0;
}

.neolib-significant-changes img {
    margin: 0 auto;
    height: 600px;
}

.neoliberalism-item {
    overflow-wrap: break-word; 
    word-wrap: break-word; 
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
}

.neoliberalism-section {
    width: 400px; 
    height: 400px;
    cursor: pointer;
    margin-bottom: 50px;
}

.neoliberalism-hide {
    width: 100%;
    background-color: #B9EAED;
    height: 400px;
    padding-left: 40px;
    display: none;
} 

.neoliberalism-item:hover .neoliberalism-hide {    
    display: block;
    font-size: 24px;
    justify-content: start;
    align-items: center;
    animation-duration: 2s;
    animation-name: fadein; /* css animation */
} 

.neoliberalism-hide p {
    line-height: 1.3;
}

@keyframes fadein {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
  
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* slideshow styles */
.neoliberalism-slideshow-container, .neoliberalism-relationship-slideshow-container, .neoliberalism-inequality-slideshow-container {
    /* max-width: 1200px; */
    position: relative;
    margin: auto;
    padding: 10px;
}

/* Hide the slides by default */
.neoliberalism-mySlides-1, .neoliberalism-mySlides-2 {
    display: none;
}

.neoliberalism-slide-image {
    vertical-align: middle;
    width: 100%; 
}

.neoliberalism-relationship-slideshow-container {
    height: 600px;
}

.neoliberalism-relationship-slideshow-container h2 {
    text-align: center;
    font-size: 40px;
}

.neolib-wrap {
    display: flex;
}

.neolib-num {
    position: relative; 
    display: inline-block; 
}

.neolib-circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #F7EBEC;
    display: inline-block;
    position: relative;
}

.neolib-inner {
    border-top-left-radius: 50%; 
    border-bottom-left-radius: 50%; 
    border-bottom-right-radius: 50%; 
    transform: rotate(45deg); 
    border: 1px solid #F5F5F5;
    background-color: #F5F5F5;
    width: 30px; 
    height: 30px; 
    position: absolute;
    top: 10px; 
    left: 10px; 
    z-index: 1; 
    display: flex; 
    align-items: center;
    justify-content: center;
}

.neolib-inner p {
    color: #272727;
    font-size: 24px;
    transform: rotate(-45deg); /* undo the rotate */
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.neolib-heading {
    width: 300px;
    height: 50px;
    background-color: #F7EBEC;
    border-radius: 100px;
    font-size: 24px;    
    font-weight: bold;
    color: #272727;
    display: flex;
    align-items: center;
    padding-left: 40px;
    margin-left: -20px;
}

.neolib-heading-p {
    width: 600px;
    line-height: 1.3;
    /* text-align: justify; */
    font-size: 24px;
    margin-left: 50px;
    color: #272727;
}

.neolib-section img {
    width: 270px;
}

.neolib-one, .neolib-five {
    margin-top: 50px;
    margin-left: 100px;
}

.neolib-two {
    margin-left: 500px;
    margin-right: 100px;
}

.neolib-three {
    margin-left: 100px;
    margin-top: 50px;
}

.neolib-four {
    margin-left: 500px;
}

.neolib-six {
    margin-left: 900px;
}

/* Financial and Economic Inequality */

.neoliberalism-inequality-slideshow-container {
    height: 800px;
    display: flex;
    justify-content: center;
} 

.neoliberalism-mySlides-2 {
    display: flex;
    align-items: center;
}

.neoliberalism-mySlides-2 img {
    height: 750px;
}

/* next and previous buttons */
.neoliberalism-prev, .neoliberalism-next {
    cursor: pointer;
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); 
    width: auto;
    padding: 16px;
    color: #272727;
    background-color: #F5F5F5;
    font-weight: bold;
    font-size: 24px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center; 
    border: none;
}

.neoliberalism-prev {
    left: 25px;
    border-radius: 3px 0 0 3px;
}
.neoliberalism-next {
    right: 25px;
    border-radius: 0 3px 3px 0;
}

.neoliberalism-prev:hover, .neoliberalism-next:hover {
    background-color: #612A6C;
    color: #F5F5F5;
}

/* dots (pagination indicators) */
.neoliberalism-dot-1, .neoliberalism-dot-2 {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.neoliberalism-dot-container{
    text-align: center;
    margin-bottom: 50px;
}

.neoliberalism-active, .neoliberalism-dot:hover {
    background-color: #717171;
}

/* fading animation for the slides */
.neoliberalism-fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

@keyframes fade {
    from { opacity: 0.4 }
    to { opacity: 1 }
}
