.mod-review-new-post-container {
    margin: 0 70px;
    display: block; 
}

.mod-review-new-post-container h2 {
    font-size: 40px;
    float: left;
    margin: 30px 0;
    font-weight: normal;
}

.review-new-post-table {
    font-size: 24px;
    clear: both;
    width: 100%;
    background-color: #F5F5F5;
    cursor: pointer;
    border-collapse: collapse;
}

.review-new-post-table th,
.review-new-post-table td {
    border-collapse: collapse;
    border: 1px solid #612A6C;
    text-align: left;
    padding: 7px 15px;
}

.review-new-post-table th {
    max-width: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
