/* ForgotPasswordPage.css */
.forgot-password-page {
  font-family: 'Poppins', sans-serif;
  width: 100%;
}

.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px); 
  padding-top: 1px; 
}

.image-placeholder {
  width: 301px;
  height: 549px;
  background-color: #F5F5F5;
  border: 1px solid #272727;
  border-radius: 10px;
  margin-right: 50px;
}

.forgot-password-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 549px;
  margin-left: 40px;
}

.forgot-password-title {
  font-size: 40px;
  font-weight: normal;
  color: #612a6c;
  margin-bottom: 20px;
  margin-top: 0; 
}

.forgot-password-input {
  width: 610px;
  height: 45px;
  padding: 0 10px;
  margin-top: 30px;
  background-color: #F5F5F5;
  border: 1px solid #272727;
  border-radius: 10px;
  font-size: 24px;
  color: #612a6c;
  margin-bottom: 10px; 
}

.forgot-password-button {
  width: 385px;
  height: 40px;
  margin-top: 30px;
  background-color: #F5F5F5;
  border: 1px solid #314397;
  color: #314397;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.forgot-password-button:hover {
  background-color: #314397;
  border: 1px solid #314397;
  color: #F5F5F5;
}

.error-message {
  color: #FF6969;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.message-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e5e4f4;
  border: 1px solid #272727;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.message-popup p {
  font-size: 24px;
  color: #612a6c;
}

/*customise the alert message*/
.swal2-popup-custom {
  background-color: #F5F5F5 !important; 
}

.swal2-title-custom {
  color: #272727 !important; 
  font-size: 36px !important;
}

.swal2-confirmbutton-custom {
  background-color: #612a6c !important; 
  color: #F5F5F5 !important;
}