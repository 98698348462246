.finan-daily-lives {
    margin: 40px 90px;
}

.finan-daily-lives-h1 {
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    position: relative; 
    display: inline-block; 
    padding-bottom: 10px; 
}
 
.finan-daily-lives-h1::after {
    content: ''; /* pseudo element */
    display: block;
    width: 100%; 
    height: 2px; 
    background-color: #612A6C;
    position: absolute; 
    bottom: 0;
    left: 0;
}

.finan-daily-lives-p {
    text-align: justify;
    line-height: 1.3;
    font-size: 24px;
    margin-top: 0;
    animation-duration: 2s;
    animation-name: slidein; /* css animation */
}

@keyframes slidein {
  from {
    translate: 150vw 0;
    scale: 150% 1;
  }

  to {
    translate: 0 0;
    scale: 100% 1;
  }
}

.key-effects-for-comm {
  display: flex;
  justify-content: space-around;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 50px;
}

.finan-daily-lives-left-icon {
  width: 50%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 300px;
  right: 0;
  margin-left: 150px;
  opacity: 0; 
  animation: fadein-slidein-left 2s ease-out forwards; /* css animation */
  animation-delay: 1s; 
}

.finan-daily-lives-left-icon img {
  height: 300px;
  max-width: 100%;
  max-height: 100%;
}

.finan-daily-lives-right-blue {
  width: 50%;
  color: #314397;
  padding: 10px 100px;
  background-color: #B9EAED;
  height: 300px;
  margin-left: 80px;
  display: flex;
  border-top-left-radius: 35%;
  border-bottom-left-radius: 30%;
  opacity: 0; 
  animation: fadein-slidein-left 2s ease-out forwards; /* css animation */
  animation-delay: 1s; 

}

.finan-daily-lives-left-pink {
  width: 50%;
  border-top-right-radius: 35%;
  border-bottom-right-radius: 30%;
  background-color: #F7EBEC;
  padding: 10px 100px;
  display: flex;
  height: 300px;
  opacity: 0;
  animation: fadein-slidein-right 2s ease-out forwards; /* css animation */
  animation-delay: 1s; 
}

.finan-daily-lives-right-icon {
  width: 50%;
  height: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadein-slidein-right 2s ease-out forwards; /* css animation */
  animation-delay: 1s; 
}

.finan-daily-lives-right-icon img {
  height: 300px;
  max-width: 100%;
  max-height: 100%;
}

.finan-daily-lives-header {
  font-size: 32px;
}

.finan-daily-lives-text {
  text-align: center;
  margin: auto;
}

@keyframes fadein-slidein-left {
    from {
        opacity: 0;
        translate: 150vw 0;
    }
    to {
        opacity: 1;
        translate: 0 0;
    }
}
@keyframes fadein-slidein-right {
  from {
      opacity: 0;
      translate: -150vw 0;
  }
  to {
      opacity: 1;
      translate: 0 0;
  }
}
