.women-lives {
    margin: 40px 90px;
}

.women-lives-h1 {
    font-size: 40px;
    text-align: left;
    font-weight: bold;
    position: relative; 
    display: inline-block; 
    padding-bottom: 10px; 
}

.women-lives-h1::after {
    content: ''; /* pseudo element */
    display: block;
    width: 100%; 
    height: 2px; 
    background-color: #612A6C;
    position: absolute; 
    bottom: 0;
    left: 0;
}

.women-lives-p {
    text-align: justify;
    line-height: 1.3;
    font-size: 24px;
    margin-top: 0;
    opacity: 0; /* invisible from the start */
    /* animation: fadein 1s ease-out forwards; css animation */
    animation: fadein 0.5s ease-out forwards;
    transition: ease 1s;
    animation-timing-function: step-end;
}

/* animation time delay to appear one after another */
.women-lives-p:nth-of-type(1) {
    animation-delay: 0s;
}

.women-lives-p:nth-of-type(2) {
    animation-delay: 0.5s;
}

.women-lives-p:nth-of-type(3) {
    animation-delay: 1s;
}

.women-lives-p:nth-of-type(4) {
    animation-delay: 1.5s;
}

.women-lives-p:nth-of-type(5) {
    animation-delay: 2s;
}

.women-lives h2 {
    text-align: center;
    font-size: 40px;
    color: #272727;
    margin-top: 50px;
    animation: fadein 0.5s ease-out forwards;
    transition: ease 1s;
    animation-timing-function: step-end;
    animation-delay: 2.5s;
    opacity: 0;
}

.women-lives-drop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    animation: fadein 0.5s ease-out forwards;
    transition: ease 1s;
    animation-timing-function: step-end;
    animation-delay: 3s;
    opacity: 0;
}

.women-lives-drop-bar {
    background-color: #612A6C;
    color: #F5F5F5;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    width: 600px;
}

.blue-bar {
    background-color: #314397;
}

.women-lives-drop-header {
    font-weight: bold;
    font-size: 24px;
    padding-left: 10px;
}

.women-lives-drop-icon {
    font-size: 24px;
    transition: transform 0.3s ease;
} 

.women-lives-drop-show {
    display: flex;
    flex-direction: column;
    width: 580px;
    padding: 10px;
}

.women-lives-drop-show img {
    width: 200px;
    margin: 0 auto;
}

.women-lives-drop-show h3 {
    font-size: 32px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0;

}

.women-lives-drop-show p {
    font-size: 24px;
    line-height: 1.3;
    text-align: justify;
}

.blue-drop-show {
    color: #314397;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
  }