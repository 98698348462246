.login-required {
    margin-top: 300px;
}

.login-required h1 {
    font-size: 40px;
}
.login-required span {
    cursor: pointer;
}

.login-required span:hover {
    background-color: #314397;
    color: #F5F5F5;
}