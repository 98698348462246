.reset-password-page {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  background-color: #e5e4f4;
}

.reset-password-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

.reset-password-title {
  font-size: 40px;
  color: #612a6c;
  font-weight: bold;
  margin-bottom: 20px;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group-password {
  width: 100%;
  max-width: 797px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group-password label {
  font-size: 24px;
  font-weight: bold;
  color: #612a6c;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
}

.input-field {
  width: 100%;
  max-width: 797px;
  height: 54px;
  border: 1px solid #612a6c;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #612a6c;
  background-color: #f5f5f5;
}

.password-input-container {
  width: 100%;
  max-width: 797px;
  height: 54px;
  position: relative;
}

.input-field-new-pass {
  width: 100%;
  height: 54px;
  border: 1px solid #612a6c;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
  color: #612a6c;
  background-color: #f5f5f5;
  padding-right: 50px; /* To avoid text overlap with the icon */
}

.password-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #612a6c;
}

.password-icon:hover {
  color: #612a6c;
}

.reset-button {
  width: 385px;
  height: 44px;
  background-color: #f5f5f5;
  border: 1px solid #314397;
  color: #314397;
  font-size: 24px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
}

/*customise the alert message*/
.swal2-popup-custom {
  background-color: #F5F5F5 !important; 
}

.swal2-title-custom {
  color: #272727 !important; 
  font-size: 36px !important;
}

.swal2-confirmbutton-custom {
  background-color: #612a6c !important; 
  color: #F5F5F5 !important;
}