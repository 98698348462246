.mod-login-page {
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-color: #E5E4F4;
}

.mod-login-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    margin-top: -100px;
}

.mod-login-section {
    width: 50%;
    padding: 20px;
    background-color: none;
}

.mod-login-title {
    font-size: 40px;
    color: #612a6c;
    font-weight: bold;
    text-align: center;
}

.mod-input-label {
    font-size: 24px;
    color: #612a6c;
    display: block;
    margin-top: 20px;
}

.mod-input-field {
    width: 390px;
    height: 55px;
    background-color: #f5f5f5;
    border-radius: 10px;
    color: #612a6c;
    border: 1px solid #612a6c;
    font-size: 24px;
    padding: 0 10px;
    box-sizing: border-box;
}

.mod-password-input-container {
    position: relative;
    width: 390px; 
}

.mod-password-input-container .mod-input-field {
    width: 100%;
    padding-right: 40px; 
}

.mod-login-eye-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #612a6c;
}

/* forgot your ... ? */
.mod-login-page p {
    color: #612a6c;
    font-size: 20px; /* do not change to 24px */
    margin-top: 10px;
    text-align: right;
    cursor: pointer;
}

.mod-forgot-password {
    color: #2275F1; 
    text-decoration: none; 
}

.mod-forgot-password a {
    text-decoration: none; 
}

.mod-login-button {
    width: 100%;
    height: 42px;
    background-color: #f5f5f5;
    border: 1px solid #314397;
    color: #314397;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    box-sizing: border-box;
}

.mod-login-button:hover {
    background-color: #314397;
    border: 1px solid #314397;
    color: #F5F5F5;
}

/*customise the alert message*/
.swal2-popup-custom {
    background-color: #F5F5F5 !important; 
}
  
.swal2-title-custom {
    color: #272727 !important; 
    font-size: 36px !important;
}
  
.swal2-confirmbutton-custom {
    background-color: #612a6c !important; 
    color: #F5F5F5 !important;
}
  
  