#faqs{
    width: auto;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#faqs .title{
    width: auto;
    text-align: center;
    padding: 0;
}

#faqs .title > p {
    font-size: 40px;
    color: #612A6C;
}

#faqs .questions{
    width: 1000px;
    height: auto;
    background-color: #F7EBEC;
    border: 1px solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    font-size: 24px;
}

#faqs .questions ul{
    list-style-type: none;
    padding: 0;
}

#faqs .questions ul li{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 950px;
    border: 1px solid;
    border-radius: 8px;
    background-color: #F5F5F5;
    transition: background-color 0.3s ease;
}

#faqs .questions ul li:hover {
    background-color: #F5F5F5;
}

#faqs .article .question-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 15px;
    cursor: pointer;
    transition: color 0.3s ease;
}

#faqs .article .question-row:hover {
    color: #612a6c;
}

.article > p{
    font-size: 24px; 
    margin-top: 10px;
    margin-bottom: 10px;
}

.answer {
    margin-left: 15px;
    margin-right: 15px;
    color: #272727;
    margin-top: 10px;
    padding: 10px 0;
}

/* Arrow styling */
.arrow {
    font-size: 24px;
    transition: transform 0.3s ease;
}

.rotate {
    transform: rotate(180deg);
}

/* Links within the #faqs section */
#faqs a {
    text-decoration: none;
    color: #272727; 
}

#faqs a:hover {
    color: #F5F5F5;
    background-color: #314397;
    text-decoration: none;
}
